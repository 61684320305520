<template>
    <div>


<ErrorAlert :msg="errorMgs" :visible="ShowError" />



 <PostsSkeleton v-if="!showContent" />
    <b-container v-if="showContent" class="mt-5 "  >
<p v-if="posts.length == 0">{{$t('No data found!')}}</p>
      <b-row>
     <b-col cols="12" lg="3" xl="3" md="6"  v-for="post in posts" :key="'category'+ post.id" >
 <b-card 
    :title="post.name"
    :img-src="$store.state.backendUrl+post.thumbnailxl"
    img-alt="Image"
    img-top
    tag="article"
    
    class="mb-2"
  >
    

    <b-button :to="{ name: 'Competition_show',params: { slug: post.slug }  }" variant="primary">المزيد</b-button>
  </b-card>

</b-col>
</b-row>

  </b-container>
</div>
</template>

<script>

import axios from 'axios'

import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
import PostsSkeleton from '@/components/skeletons/PostsSkeleton.vue'
export default {
    components:{
        ErrorAlert,PostsSkeleton
    },
    /*********************** */
    data() {
      return {
      
    
        posts:[],
        path:this.$store.state.backendUrl+"api/ar/categories/"+this.$route.params.slug,
      
        showContent: false,
        isSendingForm:false,
        errorMgs:'',
        ShowError: false,
     
     
      }
    },
    /******************* */
 
 
    methods: {
       fixingOption(json){
          var array=[];
          for (let index = 0; index < json.length; index++) {
            array[index] ={"text":this.$t(json[index].name),"value":json[index].name} ;
            
          }
           return array;
        },
         getCreateData(){
           this.showTable= false
           axios.get(this.path, {params:this.form,
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                
           
                this.posts = response.data.success.posts;
                this.showContent = true;
         
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } , 
   
   
    },
    /***************** */
     mounted() {
      this.getCreateData();
      //this.form.lang_id = this.$i18n.locale;
      if(this.$i18n.locale == 'ar') {this.localeDate ='ar-EG'}
    }
    
}
</script>